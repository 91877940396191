<script setup>
import errorPageAssets from "../assets/404.svg";
import Rusdram43 from "../assets/rusdram-43.svg";
import * as QRCode from "qrcode";

const route = useRoute();
const error = ref(null);
const canvas = ref();

onMounted(() => {
  console.log("canvas", canvas);
  QRCode.toCanvas(
    canvas.value,
    `https://rusdram.org${route.fullPath}`,
    function (error) {
      if (error) {
        this.$sentry.captureException(error);
        console.error(error);
      }
    }
  );
});
</script>

<template>
  <section class="ticket px-30 grid-cols-4">
    <header
      class="ticket__header flex flex-col space-y-3 pl-10 pt-7 justify-items-start"
    >
      <img class="w-70" :src="Rusdram43" alt="" />
      <p>
        Организатор мероприятия: Государственный русский театр драмы имени Ф.А.
        Искандера
      </p>
    </header>
    <slot />

    <aside
      class="ticket__aside flex flex-col space-y-5 pl-2 border-l border-gray-400"
    >
      <div class="flex flex-row space-x-2 px-2">
        <div>
          <r-icon :sprite="$icons" icon="location" class="h-4 w-4"></r-icon>
        </div>
        <div class="flex flex-col space-y-1">
          <h6 class="text-primary text-12">Адрес</h6>
          <div class="flex flex-col space-y-1">
            <h3 class="text-gray-dark text-base">
              Республика Абхазия, г. Сухум, проспект Леона, 8.
            </h3>
          </div>
        </div>
      </div>
      <div class="flex flex-row space-x-2 px-2">
        <div>
          <r-icon :sprite="$icons" icon="email" class="h-4 w-4"></r-icon>
        </div>
        <div class="flex flex-col space-y-1">
          <h6 class="text-primary text-12">E-mail</h6>
          <div class="flex flex-col space-y-1">
            <a href="mailto:info@rusdram.org" class="text-gray-dark text-base">
              info@rusdram.org
            </a>
          </div>
        </div>
      </div>
      <div class="flex flex-row space-x-2 px-2">
        <div>
          <r-icon :sprite="$icons" icon="phone" class="h-4 w-4"></r-icon>
        </div>
        <div class="flex flex-col space-y-1">
          <h6 class="text-primary text-12">Телефоны</h6>
          <div class="flex flex-col space-y-1">
            <a href="tel:+7 (940) 7-204-204" class="text-gray-dark text-base">
              Касса: +7 (940) 7-204-204
            </a>
            <a href="tel:+7 (940) 776-68-82" class="text-gray-dark text-base">
              Канцелярия: +7 (940) 776-68-82
            </a>
          </div>
        </div>
      </div>
      <div class="flex flex-row space-x-2 px-2">
        <div>
          <r-icon icon="computer" class="h-4 w-4"></r-icon>
        </div>
        <div class="flex flex-col space-y-1">
          <h6 class="text-primary text-12">Сайт</h6>
          <div class="flex flex-col space-y-1">
            <a href="https://rusdram.org" class="text-gray-dark text-base">
              rusdram.org
            </a>
          </div>
        </div>
      </div>
      <div class="flex flex-row space-x-2 px-2">
        <canvas ref="canvas"></canvas>
      </div>
    </aside>
    <footer class="ticket__footer">
      <p>
        Внимание! Электронный билет представляет собой цифровую запись в базе
        данных Государственного русского театра драмы имени Ф.А. Искандера
        (далее – театр). Материальным носителем электронного билета является
        файл бланка электронного билета, который направляется на электронный
        адрес покупателя, указанный при оформлении заказа, либо скачивается из
        личного кабинета. Файл необходимо распечатать для посещения мероприятия.
        Электронный билет не является бланком строгой отчётности. Покупатель
        может произвести обмен электронного билета на билет, сформированный на
        бланке строгой отчётности, в кассе театра. Все спорные вопросы решаются
        только при наличии билета, сформированного на бланке строгой отчётности.
        Возврат денежных средств в случае отмены или замены мероприятия
        осуществляется непосредственно театром при наличии билета,
        сформированного на бланке строгой отчётности. В случае копирования
        бланков электронных билетов доступ на мероприятие будет открыт только по
        тому билету, который был предъявлен первым.
      </p>
    </footer>
  </section>
</template>

<style lang="scss">
.ticket {
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  background-color: #fff;
  overflow: hidden;
  display: grid;
  gap: 27px;
  grid-template-columns: minmax(0, 1fr) 260px;
  grid-template-rows: 128px minmax(0, 1fr) 128px;
  grid-template-areas:
    "header header header header"
    "main main main aside"
    "footer footer footer footer";

  &__header {
    grid-area: header;
  }

  &__main {
    grid-area: main;
    overflow: hidden;
  }

  &__aside {
    grid-area: aside;
  }

  &__footer {
    grid-area: footer;
    font-family: "Lato";
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 12px;
  }
}
</style>
